import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import ReportTable from './ReportTable';
import GoogleFormEmbed from './GoogleFormEmbed';
import toast, { Toaster } from 'react-hot-toast';

const ReportTableWithParams = () => {
  const { token, route_id } = useParams();
  return <ReportTable token={token} route_id={route_id} />;
};

const Home = () => {
  return (
    <>
    <h1>Welcome to SubhaYatri</h1>
    </>
  )
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/booking-chart/:token/:route_id" element={<ReportTableWithParams />} />
        <Route path="/reservation-form/:token" element={<GoogleFormEmbed />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
