import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
const ReportTable = ({ token, route_id }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(["Id"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [currentLocationRowData, setCurrentLocationRowData] = useState(null);
  const [loadingLocationData, setLoadingLocationData] = useState(false);



  const refundTicket = async (e, row) => {
    if (!window.confirm("Are you sure you want to cancel this ticket?")) return;
    e.target.disabled = true;
    // alert(row['Ticket Number']);
    try {
      const response = await fetch('https://api.subhyatri.com/refund-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          route_id: route_id,
          booking_id: row.Id,
        }),
      });
      const result = await response.json();
      if (result?.status == 200) toast.success(result.message);
      else toast.error(result?.message);
      fetchData();
    } catch (error) {
      toast.error(error.message);
      console.error('Error fetching data:', error);
    } finally {
      e.target.disabled = false;
    }
  };

  const fetchLocationData = async (row = false) => {
    setLoadingLocationData(true);
    if (!row) {
      row = currentLocationRowData;
    }
    try {
      const response = await fetch('https://api.special.subhyatri.com/get-target-current-location', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          target_phone_number: row["Passenger Phone"],
        }),
      });
      const result = await response.json();
      // debugger
      if (result?.status == 200) {
        setLocationData(result.location);
      } else {
        toast.error(`${row["Passenger Phone"]} \n ${result.message}`);
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsModalOpen(false);
      toast.error(error.message);
      console.error('Error fetching data:', error);
    }


    setLoadingLocationData(false);
  };
  const handleOpenModal = async (row) => {
    fetchLocationData(row);
    setCurrentLocationRowData(row);
    setIsModalOpen(true);
  };
  const fetchData = async () => {
    try {
      // const allColumns = [
      //   "Ticket Number",
      //   "Passengers",
      //   "Seat List",
      //   "Paid Amount",
      //   "Date",
      //   "Booked by Conductor"
      // ];

      // setColumns(allColumns);

      // const sampleData = [
      //   {
      //     "Ticket Number": "NTTN625715",
      //     "Passengers": "Raju",
      //     "Seat List": "LA1",
      //     "Paid Amount": "NPR 10.00",
      //     "Date": "13/08/2024",
      //     "Booked by Conductor": null,
      //   }
      // ];

      // setData(sampleData); // Set sample data for testing

      const response = await fetch('https://api.subhyatri.com/get-booking-chart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          route_id: route_id,
        }),
      });
      const result = await response.json();

      // Assuming the first row is the column headers
      const allColumns = result.data[0];
      const hCols = result.hiddenCols;
      setHiddenColumns(hCols);
      setColumns(allColumns);

      const formattedData = result.data.slice(1).map(row => {
        let rowData = {};
        row.forEach((value, index) => {
          rowData[allColumns[index]] = value;
        });
        return rowData;
      });

      setData(formattedData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const printTable = () => {
    const printContent = document.getElementById('printableTable').outerHTML;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write('<html><head><title>Print Table</title>');
    const linkElement = printWindow.document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
    linkElement.onload = () => {
      printWindow.document.write('<style>@media print {.print-hidden { display: none; }}</style>');
      printWindow.document.write('</head><body>');
      printWindow.document.write(printContent);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    };
    printWindow.document.head.appendChild(linkElement);
  };
  return (
    <div className="flex flex-col min-h-screen">
      <LocationModal isModalOpen={isModalOpen} setLocationData={setLocationData} locationData={locationData} setIsModalOpen={setIsModalOpen} fetchLocationData={fetchLocationData} loading={loadingLocationData} />
      <header className="p-4 text-white shadow-lg bg-gradient-to-r from-blue-500 to-indigo-600">
        <div className="container flex items-center justify-between mx-auto">
          <h1 className="text-2xl font-extrabold tracking-wide">Tickets Report</h1>
          <button
            onClick={printTable}
            className="px-4 py-2 font-bold text-blue-500 bg-white rounded shadow hover:bg-gray-200"
          >
            Print
          </button>
        </div>
      </header>

      <main className="container flex-grow p-4 mx-auto">
        {isLoading ? (
          <div className="flex items-center justify-center h-[80vh]">
            <div className="relative">
              <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-lg font-semibold text-blue-500">Loading...</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="overflow-x-auto">
            {data.length === 0 && (
              <h1 className="text-2xl font-bold text-center text-blue-500 bg-white rounded shadow hover:bg-gray-200">
                No booking chart found
              </h1>
            )}
            {data.length > 0 && (
              <table id="printableTable" className="min-w-full overflow-hidden bg-white rounded-lg shadow-md">
                <thead>
                  <tr className="text-base leading-relaxed text-white uppercase bg-blue-600">
                    {columns.map((header, index) => (
                      !hiddenColumns.includes(header) && (
                        <th key={index} className="px-6 py-4 text-left">
                          {header}
                        </th>
                      )
                    ))}
                    <th className="px-6 py-4 text-left print-hidden">Get Location</th>
                    <th className="px-6 py-4 text-left print-hidden">Action</th>
                  </tr>
                </thead>
                <tbody className="text-base font-medium text-gray-700">
                  {data.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={`border-b border-gray-200 ${rowIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                        } hover:bg-gray-200`}
                    >
                      {columns.map((col, colIndex) => (
                        !hiddenColumns.includes(col) && (
                          <td key={colIndex} className="px-6 py-4 text-left whitespace-nowrap">
                            {row[col] !== null ? 
                            col == "Passenger Phone" ? `${row["Booked by Conductor"]? row["Signature"] :row[col]}` :
                            row[col]
                             : ''}
                          </td>
                        )
                      ))}

                      <td className={`px-6 py-4  print-hidden`}>
                        <button
                          className={`px-4 py-2 font-bold rounded shadow hover:bg-gray-200 text-blue-500 bg-green-100`}
                          onClick={(e) => handleOpenModal(row)}
                        >
                          Get Location
                        </button>

                      </td>

                      <td className={`px-6 py-4 ${row["Booked by Conductor"] && row["Payment Status"] != "refund_initiated" ? "text-left" : "text-center"} print-hidden`}>
                        {row["Booked by Conductor"] && row["Payment Status"] != "refund_initiated" ?
                          <button
                            className="px-4 py-2 font-bold text-red-500 bg-white rounded shadow hover:bg-gray-200"
                            onClick={(e) => refundTicket(e, row)}
                          >
                            Cancel Ticket
                          </button>
                          : row["Payment Status"] == "refund_initiated" ? "Refund Initiated" : "---"

                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </main>

      <footer className="p-4 mt-4 text-white bg-gray-800">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Subhyatri. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

const LocationModal = ({ isModalOpen, setIsModalOpen, locationData, fetchLocationData, loading }) => {

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRefresh = () => {
    fetchLocationData();
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="w-full max-w-full p-6 bg-white rounded-lg shadow-lg h-4/5 md:h-3/5 md:w-4/5 lg:w-4/5 lg:h-4/5 lg:max-w-4xl">

            <div className="flex flex-col items-center justify-between mb-4 md:flex-row">
              <h2 className="text-xl font-semibold">Location Information</h2>
              <button
                className={`${loading ? 'bg-gray-500' : 'bg-green-500'} text-white py-2 px-4 rounded`}
                onClick={handleRefresh}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 000 8v4a8 8 0 01-8-8z"
                      ></path>
                    </svg>

                    Refreshing...
                  </div>
                ) : (
                  'Refresh'
                )}
              </button>
              <button
                className="ml-auto text-lg text-red-500 transition-colors duration-200 hover:text-red-700 md:ml-0"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>

            {locationData ? (
              <>
                <p className="mb-2">
                  <strong>Last Updated:</strong> {locationData.last_update}
                </p>

                <div className="h-[55vh] mb-4 ">
                  <iframe
                    width="100%"
                    height="100%"
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps?q=${locationData.coordinates}&output=embed`}
                    title="Google Maps"
                    className="rounded-lg"
                  ></iframe>
                </div>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};



/* eslint-enable no-unused-vars */
/* eslint-enable react-hooks/exhaustive-deps */
export default ReportTable;
